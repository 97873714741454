let customWindow: any = window;

export class AppSettings {
  public static APP_NAME = 'Fanaticka';
  public static BASE_API_URL: string = customWindow.API_URL;
  public static IMAGES_PATH = '/content/img';
  public static DEFAULT_FONTS =
    '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji';
  public static LOCALHOST = 'localhost';
  //:ToDo get rid of this approach
  public static IS_DEV: boolean = customWindow.ENVIRONMENT === 'development' || customWindow.ENVIRONMENT === 'stage';
}

export class AuthSettings {
  public static CLIENT_ID = 'fanaticka_web';
  public static CLIENT_SECRET = '0fa8b57c0e144b1786907b4977fbb2a1';
  public static GOOGLE_CLIENT_ID = '5471521824-plpatvdr32fctgob0bsiso9s2udagvvk.apps.googleusercontent.com';
  public static GOOGLE_SDK = 'https://accounts.google.com/gsi/client';
  public static FACEBOOK_APP_ID = '3434193566817012';
  public static FACEBOOK_SDK = 'https://connect.facebook.net/en_US/sdk.js';
}

export class IntercomSettings {
  public static APP_ID = 'uaod29y5';
}

export class AnalyticsSettings {
  public static GOOGLE_TRACKING_ID = 'UA-128487417-1';
  public static APP_INSIGHTS_ID: string = customWindow.APP_INSIGHTS_ID;
}

export class DomainSetting {
  public static By = 'by';
  public static Ru = 'ru';
  public static Club = 'club';
  public static Co = 'co';
  public static Info = 'info';
  public static Live = 'live';
  public static Me = 'me';
  public static Eu = 'eu';
  public static Pl = 'pl';
  public static Com = 'com';
}

export class LanguageSetting {
  public static Ru = 'ru';
  public static En = 'en';
}

export class StripeSettings {
  public static STRIPE_KEY: string = customWindow.STRIPE_KEY;
  public static STRIPE_TM_KEY: string = customWindow.STRIPE_TM_KEY;
}
